import React from "react";
import "../App.css";
import logo from "../images/companylogo.svg";

const Loading = ({ progress }) => (
  <div className="loading-spinner-container">
    <img src={logo} alt="Company Logo" className="loading-logo" />
    <div className="spinner"></div>
    <div className="loading-percentage">{progress}%</div>
  </div>
);

export default Loading;
