import React from 'react';
import '../App.css';

export default function PrivacyPolicy() {
    return (
        <div className="privacy-container">
            <h1 className="privacy-title">Privacy Policy</h1>
            <p className="privacy-date">Effective Date: 01-08-2024</p>
            <p className="privacy-intro">
                Barde Infra provides interior services through its Website (“Website” or “Site”) www.bardeinfra.com. This Privacy Policy outlines how Barde Infra ("we," "our," "us") collects, uses, and protects the information you provide to us when you visit and use our Site.
            </p>
            <div className="privacy-section">
                <h2 className="privacy-heading">1. Information We Collect</h2>
                <ul className="privacy-list">
                    <li className="privacy-list-item">
                        <strong>Personal Information:</strong> When you visit our Site, subscribe to our newsletter, request information, or make an inquiry, we may collect personal information such as your name, email address, phone number, and any other details you provide voluntarily. To assist us in meeting business operations needs and to perform certain services and functions, we may provide Personal Information to vendors and service providers, including providers of hosting services, customer service vendors, cloud services, email communication software, web analytics services, and other information technology providers, among others. Pursuant to our instructions, these parties will access, process, or store Personal Information only in the course of performing their duties to us.
                    </li>
                    <li className="privacy-list-item">
                        <strong>Non-Personal Information:</strong> We may also collect non-personal information automatically when you interact with our Site, such as your browser type, device information, and browsing habits.
                    </li>
                </ul>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">2. How We Use Your Information</h2>
                <ul className="privacy-list">
                    <li className="privacy-list-item">
                        <strong>To Improve Our Services:</strong> We analyze the data we collect to understand user behavior and preferences, which helps us improve our Site and services.
                    </li>
                    <li className="privacy-list-item">
                        <strong>To Communicate with You:</strong> We use your contact information to respond to inquiries, send updates, and provide information about our services.
                    </li>
                    <li className="privacy-list-item">
                        <strong>Marketing and Promotional Purposes:</strong> With your consent, we may send you newsletters, promotions, and other marketing materials related to Barde Infra.
                    </li>
                    <li className="privacy-list-item">
                        <strong>To Comply with Legal Obligations:</strong> We may use your information to comply with applicable laws, regulations, and legal processes.
                    </li>
                </ul>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">3. Cookies and Tracking Technologies</h2>
                <p className="privacy-text">
                    Our Site may use cookies and similar tracking technologies to enhance your experience. Cookies are small files stored on your device that help us understand your preferences and track usage patterns. You can adjust your browser settings to refuse cookies or notify you when they are being used. However, some parts of our Site may not function properly without cookies.
                </p>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">4. How We Protect Your Information</h2>
                <p className="privacy-text">
                    We take the security of your information seriously and implement appropriate technical and organizational measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </p>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">5. Sharing Your Information</h2>
                <ul className="privacy-list">
                    <li className="privacy-list-item">
                        <strong>Service Providers:</strong> We may share your information with trusted service providers who assist us in operating our Site, conducting business, or servicing you, provided they agree to keep your information confidential.
                    </li>
                    <li className="privacy-list-item">
                        <strong>Legal Requirements:</strong> We may disclose your information if required by law or in response to legal processes, such as a court order or subpoena.
                    </li>
                </ul>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">6. Third-Party Links</h2>
                <p className="privacy-text">
                    Our Site may contain links to third-party websites. These websites have their own privacy policies, and we are not responsible for their content or practices. We encourage you to review the privacy policies of these third-party sites before providing any personal information.
                </p>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">7. Your Rights</h2>
                <p className="privacy-text">
                    You have the right to access, correct, update, or delete your personal information. If you wish to exercise any of these rights, please contact us at contact@bardeinfra.com. We will respond to your request within a reasonable timeframe.
                </p>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">8. Changes to This Privacy Policy</h2>
                <p className="privacy-text">
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with the updated effective date. We encourage you to review this page periodically to stay informed about how we protect your information.
                </p>
            </div>
            <div className="privacy-section">
                <h2 className="privacy-heading">9. Contact Us</h2>
                <p className="privacy-text">
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                </p>
                <p className="privacy-text">
                    Barde Infra<br />
                    Email: contact@bardeinfra.com<br />
                    Phone: +91 94039 19640<br />
                    Address: First floor, Above CSB Bank, Opp. Thyssenkrupp Industry, Near Ambedkar Chowk, Pimpri, Pune - Maharashtra, India-411018.
                </p>
            </div>
        </div>
    );
}
