import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap-icons/font/bootstrap-icons.css";
import { ReactComponent as CompanyLogo } from "../images/companylogo.svg";
import { MdOutlineCall, MdMailOutline } from "react-icons/md";

export default function Footer() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const location = [
    {
      name: "Interior Design in Pune",
    },
    {
      name: "Interior Design in mumbai",
    },
    {
      name: "Interior Design in New Mumbai",
    },
    {
      name: "Interior Design in Delhi",
    },
    {
      name: "Interior Design in Punjab",
    },
    {
      name: "Interior Design in Goa",
    },
    {
      name: "Interior Design in Nashik",
    },
    {
      name: "Interior Design in Jaipur",
    },
    {
      name: "Interior Design in Patna",
    },
    {
      name: "Interior Design in Surat",
    },
    {
      name: "Interior Design in Kolkata",
    },
    {
      name: "Interior Design in Chennai",
    },
    {
      name: "Interior Design in Coimbatore",
    },
  ];

  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className="container-fluid footer-css">
        <div className="container">
          <div className="footer-info">
            <div className="row">
              <div className="col-md-5">
                <div className="company-info">
                  <CompanyLogo className="logo" />
                  <p>
                    {" "}
                    First floor, Above CSB Bank, Opp. Thyssenkrupp Industry,
                    Near Ambedkar Chowk, Pimpri, Pune - Maharashtra,
                    India-411018.
                  </p>
                  <p>
                    <MdOutlineCall /> +91-9403919640
                  </p>
                  <p>
                    <MdMailOutline />
                    contact@bardeinfra.com
                  </p>
                </div>
              </div>
              <div className="col-md-2">
                <div className=" company-info ">
                  <h6 className=" text-white">Company</h6>
                  <ul className="list-unstyled  mt-4">
                    <li>
                      <Link
                        className="nav-link1"
                        to="/aboutus"
                        onClick={scrollToTop}
                      >
                        About us
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="nav-link1"
                        to="/privacy-policy"
                        onClick={scrollToTop}
                      >
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2">
                <div className="company-info">
                  <h6 className="text-white">Support</h6>
                  <ul className="list-unstyled footer-link mt-4">
                    <li>
                      <Link
                        className="nav-link1"
                        to="/contactus"
                        onClick={scrollToTop}
                      >
                        Contact us
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 company-info social">
                <h6>Social</h6>
                <div className="social-icons">
                  <Link
                    to="https://www.facebook.com/profile.php?id=100087383364723"
                    target="blank"
                  >
                    <i className="bi bi-facebook"></i>
                  </Link>
                  <Link to="https://twitter.com/BardeInfra" target="blank">
                    <i className="bi bi-twitter"></i>
                  </Link>
                  <Link
                    to="https://www.linkedin.com/company/87418830/admin/feed/posts/"
                    target="blank"
                  >
                    <i className="bi bi-linkedin"></i>
                  </Link>
                  <Link
                    to="https://www.instagram.com/bardeinfra_interiors"
                    target="blank"
                  >
                    <i className="bi bi-instagram"></i>
                  </Link>
                  <Link
                    to="https://www.youtube.com/channel/UC7PNIT6eYIINO8zCrYRJTtg"
                    target="blank"
                  >
                    <i className="bi bi-youtube"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className=" footer-locations  ">
            <div className="row">
              <div className="col-md-12 locationhead">
                <h6>Locations</h6>
              </div>
            </div>
            <div className="row my-3">
              {location.map((location, index) => (
                <div key={index} className="col-3 text-white">
                  <ul>
                    <li>
                      {" "}
                      <p className="locationname">{location.name}</p>
                    </li>
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center copyright">
              <p>
                Copyright &copy; {currentYear} All Rights Reserved. BARDE INFRA
                SOLUTIONS
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
