import React, {useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Form } from "react-bootstrap";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import { ReactComponent as CompanyLogo } from "../images/companylogo.svg";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [designs, setDesigns] = useState([]);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [query, setQuery] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [filteredDesigns, setFilteredDesigns] = useState([]);
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL;
  const API_KEY = process.env.REACT_APP_API_KEY;
  const TOKEN = process.env.REACT_APP_TOKEN;

  const customKeywords = [
    "book",
    "designer",
    "consultation",
    "estimate",
    "get",
  ];

  function handleClick() {
    window.scrollTo(0, 0);
  }

  const handleNavbarItemClick = () => {
    setDropdownOpen1(false);
    setDropdownOpen(false);
    const toggler = document.querySelector(".navbar-toggler");
    if (toggler && toggler.ariaExpanded === "true") {
      toggler.click();
    }
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const fetchDesigns = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/api/auth/get-design`, {
            headers: {
              'x-api-key': API_KEY,
              'Authorization': `Bearer ${TOKEN}`
            }
          }
        );
        setDesigns(response.data);
      } catch (error) {
        console.error("Error fetching designs:", error);
      }
    };

    fetchDesigns();
  }, []);

  useEffect(() => {
    if (query) {
      const lowerCaseQuery = query.trim().toLowerCase();
      const filtered = designs.filter((design) =>
        design.newDesignName.toLowerCase().includes(lowerCaseQuery)
      );

      const isCustomKeyword = customKeywords.some((keyword) =>
        keyword.startsWith(lowerCaseQuery)
      );

      if (isCustomKeyword || filtered.length === 0) {
        setFilteredDesigns([{ newDesignName: "Contact Us" }]);
      } else {
        setFilteredDesigns(filtered);
      }

      setShowSuggestions(true);
    } else {
      setFilteredDesigns([]);
      setShowSuggestions(false);
    }
  }, [query, designs]);

  const handleDesignNameClick = (name) => {
    if (name === "Contact Us") {
      navigate("/contactus");
    } else if (name !== "All") {
      navigate(`/design/${encodeURIComponent(name)}`);
      setSelectedDesign(name);
    } else {
      navigate("/design");
    }
    setDropdownOpen(false);
    window.scrollTo(0, 0);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const lowerCaseQuery = query.trim().toLowerCase();

    // Check if the query matches any custom keywords
    const isCustomKeyword = customKeywords.some((keyword) =>
      keyword.startsWith(lowerCaseQuery)
    );

    if (isCustomKeyword || filteredDesigns.length === 0) {
      navigate("/contactus");
    } else if (filteredDesigns.length > 0) {
      navigate(
        `/design/${encodeURIComponent(filteredDesigns[0].newDesignName)}`
      );
    }

    setShowSuggestions(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event);
    }
  };

  return (
    <div className="header fixed-top container-fluid">
      <Navbar expand="lg" className="navbar navbar-expand-lg navbar-light">
        <Navbar.Brand as={Link} to="/" onClick={handleNavbarItemClick}>
          <CompanyLogo className="logo" onClick={handleClick} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarSupportedContent" />
        <Navbar.Collapse id="navbarSupportedContent">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/" onClick={handleNavbarItemClick}>
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/aboutus" onClick={handleNavbarItemClick}>
              About us
            </Nav.Link>
            {designs.length > 0 && (
              <NavDropdown
                title="Services"
                id="basic-nav-dropdown"
                show={dropdownOpen}
                onMouseEnter={() => setDropdownOpen(true)}
                onMouseLeave={() => setDropdownOpen(false)}
                onClick={() => setDropdownOpen1(!dropdownOpen1)}
              >
                <div className="dropdown-menu-row">
                  {designs.map((design, index) => (
                    <NavDropdown.Item
                      key={index}
                      as={Link}
                      to={`/design/${encodeURIComponent(design.newDesignName)}`}
                      className={
                        selectedDesign === design.newDesignName
                          ? "selected"
                          : ""
                      }
                      onClick={() => {
                        handleDesignNameClick(design.newDesignName);
                        handleNavbarItemClick();
                      }}
                    >
                      {design.newDesignName}
                    </NavDropdown.Item>
                  ))}
                </div>
              </NavDropdown>
            )}
            <Nav.Link as={Link} to="/gallery" onClick={handleNavbarItemClick}>
              Gallery
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/luxurydesign"
              onClick={handleNavbarItemClick}
            >
              Luxury Design
            </Nav.Link>
            <Nav.Link as={Link} to="/contactus" onClick={handleNavbarItemClick}>
              Contact Us
            </Nav.Link>
          </Nav>
          <Form
            className="search-form form-inline my-2 my-lg-0 position-relative"
            onSubmit={handleSearch}
          >
            <CiSearch className="search-icon" onClick={handleSearch} />
            <input
              className="form-control md-2"
              placeholder="Search Services"
              value={query}
              onChange={(event) => setQuery(event.target.value)}
              onKeyPress={handleKeyPress}
            />

            {showSuggestions && query && (
              <div className="suggestions-dropdown">
                <ul className="suggestions-list">
                  {filteredDesigns.map((design, index) => (
                    <li key={index}>
                      <Link
                        to={`${
                          design.newDesignName === "Contact Us"
                            ? "/contactus"
                            : `/design/${encodeURIComponent(
                                design.newDesignName
                              )}`
                        }`}
                        onClick={() => {
                          setQuery("");
                          setShowSuggestions(false);
                        }}
                      >
                        {design.newDesignName}
                      </Link>
                    </li>
                  ))}
                  {filteredDesigns.length === 0 && (
                    <li className="no-suggestions">No suggestions found</li>
                  )}
                </ul>
              </div>
            )}
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
