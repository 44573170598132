import React, {
  Suspense,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ReactWhatsapp from "react-whatsapp";
import Header from "./components/Header";
import Footer from "./components/Footer";
import PhoneIcon from "./components/PhoneIcon";
import LoadingSpinner from "./components/LoadingBar";
import "./App.css";
import PrivacyPolicy from "./components/privacypolicy";



const Home = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/Home")
);
const Gallery = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/Gallery")
);
const Luxurydesign = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/Luxurydesign")
);
const Contactus = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/Contactus")
);
const Designdetails = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/Designdetails")
);
const About = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/About")
);
const LandingPage = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/LandingPage")
);
const OurDesign = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./components/OurDesign")
);

function App() {
  const location = useLocation();
  const excludeGlobalStyles = useMemo(
    () =>
      location.pathname === "/landingpage" ||
      location.pathname === "/ourdesign",
    [location.pathname]
  );

  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);

  const handleLoading = useCallback(() => {
    if (location.pathname === "/") {
      setLoading(true);
      const interval = setInterval(() => {
        setProgress((prevProgress) => {
          const nextProgress = Math.min(prevProgress + 10, 100);
          if (nextProgress === 100) {
            clearInterval(interval);
            setLoading(false);
          }
          return nextProgress;
        });
      }, 500);
      return () => clearInterval(interval);
    } else {
      setLoading(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    handleLoading();
  }, [location.pathname, handleLoading]);

  return (
    <div className={excludeGlobalStyles ? "" : "App"}>
      {!excludeGlobalStyles && <MemoizedHeader />}
      <div className="btnwrap">
        <ReactWhatsapp
          number="9403919640"
          className="whatsapp"
          message="Hey, I'm looking for interior design services. Could we schedule a Call?"
        />
        {isMobile && <PhoneIcon number="9403919640" />}
      </div>
      <Suspense fallback={<LoadingSpinner progress={0} />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/design/:designId" element={<Designdetails />} />
            <Route path="/aboutus" element={<About />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/luxurydesign" element={<Luxurydesign />} />
            <Route path="/contactus" element={<Contactus />} />
            <Route path="/landingpage" element={<LandingPage />} />
            <Route path="/ourdesign" element={<OurDesign />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        
      </Suspense>
      {!excludeGlobalStyles && <MemoizedFooter />}
    </div>
  );
}

const MemoizedHeader = React.memo(Header);
const MemoizedFooter = React.memo(Footer);

export default App;
